/**
 * @generated SignedSource<<14d680d1dd9695cd1647d728b26dfdd1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type NatureOfWorkSubCategoryEnum = "accessory" | "airports" | "antennas" | "aqueductsSewer" | "blade" | "bridges" | "cages" | "chambers" | "dams" | "demolition" | "distribution" | "docks" | "excavation" | "gearboxes" | "generator" | "gondola" | "hand" | "handling" | "landscaping" | "lifting" | "lines" | "modules" | "newConstruction" | "nonOperated" | "powerPlants" | "pruning" | "pylons" | "refurbishment" | "retentionBasins" | "roadsRails" | "rotor" | "shelters" | "substations" | "towers" | "transformers" | "transport" | "ventilationPlumbingElectrical" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NatureOfWorkFields_CategoriesFragment$data = {
  readonly natureOfWorkSubCategories: ReadonlyArray<NatureOfWorkSubCategoryEnum>;
  readonly " $fragmentType": "NatureOfWorkFields_CategoriesFragment";
};
export type NatureOfWorkFields_CategoriesFragment$key = {
  readonly " $data"?: NatureOfWorkFields_CategoriesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NatureOfWorkFields_CategoriesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NatureOfWorkFields_CategoriesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "natureOfWorkSubCategories",
      "storageKey": null
    }
  ],
  "type": "NatureOfWorkLookup",
  "abstractKey": null
};

(node as any).hash = "791257f40e3bc0ac0b80061ea7e2c515";

export default node;
